.ham {
  cursor: pointer;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--text-color);
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham1 .top {
  /* The thousandth fixes a weird missing part of the stroke on iOS Safari */
  stroke-dasharray: 40.001 139;
}
.ham1 .bottom {
  stroke-dasharray: 40.001 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}
