.games > .game {
  --width-rate: 0.6;
  --width: calc(var(--width-rate) * 100%);
  --edge-width: calc((100% - var(--width)) * (0.5 / var(--width-rate)));

  align-items: center;
  background-color: var(--gradient-color);
  cursor: pointer;
  display: flex;
  margin: auto;
  max-width: var(--width);
  min-height: 300px;
  position: relative;
}

.games > .game::before,
.games > .game::after {
  content: '';
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0;
  width: var(--edge-width);
}

.games > .game::before {
  background-image: linear-gradient(90deg, transparent, var(--gradient-color));
  left: calc(-1 * var(--edge-width));
}

.games > .game::after {
  background-image: linear-gradient(90deg, var(--gradient-color), transparent);
  left: 100%;
}

.games > .game.cat-miner {
  --gradient-color: var(--color-cat-miner);
}

.games > .game.not-to-scale {
  --gradient-color: var(--color-not-to-scale);
}

/* Shared CSS for game pages */

.game-page > section {
  padding: 3rem 1rem;
  position: relative;
}

.game-page > section > :first-child {
  margin-top: 0;
}

.game-page > section > .youtube-video {
  aspect-ratio: 16 / 9;
  border: 0px;
  display: block;
  margin: auto;
  position: relative;
  width: calc(min(100%, 90vw));
}

.game-page > section > .logo {
  max-width: 30%;
}

.mobile .game-page > section > .logo {
  max-width: 50%;
}

.game-page > section > img,
.game-page > section > video,
.game-page > section > .carousel {
  max-width: 80%;
}

.mobile .game-page > section > a {
  display: block;
}

.game-page > section > img + img,
.mobile .game-page > section > a + a {
  /* Separate neighboring images and links */
  margin: 1rem auto 0;
}

.game-page > section > a + a {
  margin-left: 1rem;
}

.game-page > section:nth-child(2n) {
  --bg-color: var(--section-color);
  background-color: var(--bg-color);
  color: var(--stripe-color);
}

.game-page > section:nth-child(2n)::after {
  background-color: var(--bg-color);
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  left: -100vw;
  width: 250vw;
  z-index: -1;
}

/* hax :( */

.home .games > .title {
  display: none;
}
