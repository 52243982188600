.home > .hero {
  --hero-height: calc(100dvh - var(--header-height));
  --font-size: min(3rem, 4dvh);

  font-size: var(--font-size);
  height: var(--hero-height);
  overflow: hidden;
  position: relative;
  width: 100vw;
}

.home > .hero > p {
  --base-font-size: 2vw;

  left: 50%;
  position: absolute;
  text-shadow: 0 0 5px var(--main-color), 0 0 6px var(--main-color),
    0 0 10px var(--main-color), 0 0 15px var(--main-color),
    0 0 20px var(--main-color);
  top: 40%;
  transform: translate(-50%, -50%);
  width: 40%;
}

@media screen and (max-width: 1400px) {
  :not(.mobile) {
    .home > .hero {
      --font-size: min(3rem, 3.85vw, 3.5dvh);
    }
  }

  :not(.mobile) .home > .hero > p {
    top: 50%;
    width: 60%;
  }
}

.home > .hero > img {
  height: 100%;
  object-position: 20%;
  object-fit: cover;
  width: 100%;
}

.home > .hero > .games {
  bottom: 10%;
  display: flex;
  justify-content: space-around;
  height: 10%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 50%;
}

.home > .hero > .games > a {
  height: 100%;
  width: 48%;
}

.home > .hero > .games > a > button {
  height: 100%;
  line-height: 100%;
  padding: 0 0 1% 0;
  width: 100%;
}

.mobile .home > .hero {
  --font-size: min(2.5rem, 7vw, 2.75dvh);

  height: calc(100dvh - var(--header-height));
}

.mobile .home > .hero > p {
  top: 10%;
  transform: translateX(-50%);
  width: 80%;
}

.mobile .home > .hero > .games {
  align-items: center;
  bottom: 5%;
  flex-direction: column;
  gap: 15%;
  height: 25%;
  justify-content: space-between;
  width: 100%;
}

.mobile .home > .hero > .games > a {
  height: 100%;
  width: 80%;
}

.mobile .home > .hero > .games > a > button {
  height: 100%;
  width: 100%;
}
