.carousel {
  margin: auto;
  position: relative;
  user-select: none;
}

.carousel-strip {
  display: flex;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  user-select: none;
}

.carousel-strip.dragging {
  scroll-snap-type: none;
}

.carousel-strip.transitioning {
  pointer-events: none;
}

.carousel-strip > img,
.carousel-strip > video {
  pointer-events: none;
  object-fit: cover;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.carousel-btn-nav {
  bottom: 30px;
  display: flex;
  gap: 15px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.mobile .carousel-btn-nav {
  bottom: 15px;
  gap: 8px;
}

.carousel-btn-nav-btn {
  background-color: #fff8;
  border-radius: 10px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.mobile .carousel-btn-nav-btn {
  border-radius: 0;
  bottom: 15px;
  height: 3px;
}

.carousel-btn-nav-btn.active {
  background-color: #fff;
}

/* .mobile .carousel-arrow-nav {
  display: flex;
} */

.carousel-arrow-nav {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  /* display: none; */
  height: 100%;
  justify-content: space-between;
  left: 0;
  padding: 0.5rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-arrow-nav > .carousel-arrow-nav-arrow {
  --size: calc(min(8vw, 65px));

  background-color: #fff8;
  border-radius: 4vw;
  cursor: pointer;
  display: block;
  font-size: calc(var(--size) * 0.4);
  /* Offsetting font's asymmetrical anchor point */
  line-height: calc(var(--size) * 0.95);
  height: var(--size);
  pointer-events: all;
  width: var(--size);
}
