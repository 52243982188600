:root {
  --header-height: 70px;
}

@media screen and (min-width: 2000px) {
  :root {
    --header-height: 90px;
  }
}

.header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 66%;
  height: var(--header-height);
  justify-content: space-between;
  overflow: hidden;
  padding: 0 5px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;
}

.header > .background {
  height: 100%;
  width: 100%;
}

.header > .logo {
  padding: 0 1rem;
  position: relative;
  transition: filter 75ms ease-out;
}

.header > .logo > .double {
  height: 100%;
  left: 0;
  filter: blur(5px);
  opacity: 0;
  padding: 0 1rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: calc(100% - 2rem);
  z-index: -1;
}

.header > .logo:hover > .double {
  opacity: 0.3;
}

.header > .logo:active > .double {
  opacity: 0.8;
}

.header > .logo > img {
  cursor: pointer;
  max-height: calc(var(--header-height) - 10px);
}

.header > .links {
  align-items: center;
  display: inline-flex;
  padding: 5px 10px;
}

.header > .links > .link {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  text-align: center;
}

@media screen and (min-width: 2000px) {
  .header {
    padding: 0 10px;
  }

  .header > .links > .link {
    margin: 0 15px;
  }
}

.header > .links > .link.active,
.header-menu > .links > .link.active {
  font-weight: bold;
}

.header > .hamburger-btn {
  height: 80px;
}

.header-menu {
  --element-height: 80px;
  --number-of-elements: 0; /* Set in JS */
  --menu-height: calc(var(--element-height) * var(--number-of-elements));

  background-image: linear-gradient(
    to bottom,
    var(--main-color),
    var(--dark-color) 98%,
    var(--dark2-color)
  );
  position: fixed;
  right: 0;
  top: var(--header-height);
  transition: top 0.15s ease-out, height 0.15s ease-out;
  width: 100%;
  z-index: 2;
}

.header-menu > .links {
  height: 0;
  overflow: hidden;
}

.header-menu > .links > .link {
  cursor: pointer;
  display: block;
  height: var(--element-height);
  /* Offset for better center-align with font */
  line-height: calc(var(--element-height) - 3px);
  padding: 0 25px;
  text-align: right;
}

.header-menu > .opaque {
  background-image: linear-gradient(#0000);
  cursor: inherit;
  height: 100vh;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: background-image 0.3s ease-in-out, top 0.15s ease-out;
  width: 100vw;
}

.header-menu.open > .links {
  height: var(--menu-height);
}

.header-menu.open .opaque {
  background-image: linear-gradient(to bottom, #0003, #0002 90%, #0000);
  /* background-color: #0003; */
  pointer-events: all;
  top: var(--menu-height);
}

.header a,
.header-menu a {
  text-decoration: none;
  transition: text-shadow 75ms ease-out;
}

.header a:hover,
.header-menu a:hover {
  text-shadow: 0 0 5px var(--stripe-color), 0 0 10px var(--stripe-color),
    0 0 20px var(--stripe-color);
}

.header a:active,
.header-menu a:active {
  text-shadow: 0 0 5px var(--stripe-color), 0 0 6px var(--stripe-color),
    0 0 10px var(--stripe-color), 0 0 15px var(--stripe-color),
    0 0 20px var(--stripe-color), 0 0 30px var(--stripe-color),
    0 0 40px var(--stripe-color);
}
