.footer {
  display: flex;
  font-size: 60%;
  justify-content: space-between;
  padding: 2rem;
}

.footer > .links {
  display: flex;
  gap: 1rem;
}

.footer > .links > .link {
  cursor: pointer;
}
