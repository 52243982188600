:root {
  --color-home: #f0e5d6;
  --color-home-stripe: #a4406233;
  --color-home-dark: #e9f3f5;
  --color-home-dark2: #e3f0f3;
  --color-home-text: #414546;
  --color-home-button: #414546;
  --color-home-button-hover: #5c6163;
  --color-home-button-active: #7d8486;

  --color-cat-miner: #fff816;
  --color-cat-miner-section: #141413;
  --color-cat-miner-stripe: #fffde5;
  --color-cat-miner-dark: #e9e10b;
  --color-cat-miner-dark2: #e6de06;

  --color-not-to-scale: #c9d4cc;
  --color-not-to-scale-section: #131413;
  --color-not-to-scale-stripe: #94cca5;
  --color-not-to-scale-dark: #b4c0b8;
  --color-not-to-scale-dark2: #a9bbaf;
}

body.home {
  --main-color: var(--color-home);
  --stripe-color: var(--color-home-stripe);
  --dark-color: var(--color-home-dark);
  --dark2-color: var(--color-home-dark2);
}

body.cat-miner {
  --main-color: var(--color-cat-miner);
  --section-color: var(--color-cat-miner-section);
  --stripe-color: var(--color-cat-miner-stripe);
  --dark-color: var(--color-cat-miner-dark);
  --dark2-color: var(--color-cat-miner-dark2);
}

body.not-to-scale {
  --main-color: var(--color-not-to-scale);
  --section-color: var(--color-not-to-scale-section);
  --stripe-color: var(--color-not-to-scale-stripe);
  --dark-color: var(--color-not-to-scale-dark);
  --dark2-color: var(--color-not-to-scale-dark2);
}

.App {
  --text-color: var(--color-home-text);
  --button-color: var(--color-home-button);
  --button-hover-color: var(--color-home-button-hover);
  --button-active-color: var(--color-home-button-active);

  box-sizing: border-box;
  color: var(--color-home-text);
  font-family: 'Vanilla Extract';
  font-size: 1.5rem;
  overflow: hidden auto;
  text-align: center;
  top: 0;
  width: 100vw;
}

@media screen and (min-width: 2000px) {
  .App {
    font-size: 2.25rem;
  }
}

.App.mobile {
  font-size: 1.2rem;
}

a {
  color: inherit;
}

button {
  background-color: var(--text-color);
  border: inherit;
  border-radius: 0;
  color: var(--main-color);
  cursor: pointer;
  font: inherit;
  padding: 1rem 2rem;
  transition: background-color 75ms ease-out;
}

button:hover {
  background-color: var(--button-hover-color);
}

button:active {
  background-color: var(--button-active-color);
}

img,
video {
  /* Prevent overflowing containers */
  max-width: 100%;
  /* Prevent invisible bottom margins */
  vertical-align: middle;
}

.background {
  transition: background 0.3s ease-out;
  background-color: var(--main-color);
}

body {
  height: 100%;
  width: 100vw;
}

html {
  overflow-x: hidden;
}
