.frame {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100%;
  padding-top: var(--header-height);
}

.frame > .page:not(.hero) {
  margin: auto;
  max-width: 1600px;
}
